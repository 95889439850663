import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { HelperService } from '../helper.service';
@Injectable({
    providedIn: 'root'
  })
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService, private router: Router,private helperService : HelperService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let checkToken = `${this.auth.getToken()}`;
      if(checkToken !== 'undefined'){
        request = request.clone({
          setHeaders: {
              Authorization: `${this.auth.getToken()}`,
              inspector_id : `${this.helperService.getInspectorid()}`
          }
        });
      }
      
      return next.handle(request).pipe(
        catchError(
          (err, caught) => {
            console.log(err)
            if (err.status === 401){
              this.handleAuthError();
              return of(err);
            }
            throw err;
          })
          );
    }
    private handleAuthError(){
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }