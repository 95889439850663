import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../services/helper.service';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  headerDetail:any;
  errorMsg = '';
  constructor(
    private helperService: HelperService,
    private toastr: ToastrService,
    private loginService: LoginService,
    private router : Router
  ) { 

  }
  ngOnInit() {
    this.inspectorDetail();
  }
  gohome(){
    this.router.navigate(['/home']);
  }
  inspectorDetail(){
    this.helperService.inspectorDetail().subscribe( 
      response => {
          if(response.success === true){
              if(response.data.length >0 ) { 
                this.headerDetail = response.data[0].inspectorDetail[0];
              }else {
                this.toastr.success('No More data found! ','No Data',{
                  timeOut: 3000
                })
              }
          }
      },
      error => { 
        this.errorMsg = error.statusText 
      })
  }
  //------logout Inspector--
  logoutUser(){
    this.loginService.logOut();
  }
}
