import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError,shareReplay } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  baseUrl: any = `${environment.baseUrl}inspector`;

  constructor(private http: HttpClient) { }

  inspectorDetail(inspectorData) {
    return this.http.post<any>(`${this.baseUrl}/inspectorDetail`, inspectorData).pipe(catchError(this.errorHandler),shareReplay())
  }
  claimSummary(dataObj) {
    return this.http.post<any>(`${this.baseUrl}/overviewCount`, dataObj).pipe(catchError(this.errorHandler),shareReplay())
  }
  holidaysList(){
    return this.http.get<any>(`${this.baseUrl}/holidaysList`).pipe(catchError(this.errorHandler),shareReplay())
  }
  myHolidays(inspectorData){
    return this.http.post<any>(`${this.baseUrl}/getMyVacation`, inspectorData).pipe(catchError(this.errorHandler),shareReplay())
  }
  addHolidays(dataObj){
    //console.log(dataObj);
    return this.http.post<any>(`${this.baseUrl}/addVacation`, dataObj).pipe(catchError(this.errorHandler),shareReplay())
  }
  //-----------delete Vacation---
  delVacation(dataObj){
    return this.http.post<any>(`${this.baseUrl}/delVacation`, dataObj).pipe(catchError(this.errorHandler),shareReplay())
  }
  errorHandler(error: HttpErrorResponse) {
    return throwError(error)
  }
}
