import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { throwError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {
  baseUrl: any = `${environment.baseUrl}archive`;
  constructor(private http: HttpClient) { }
  
  // claim list
  getClaimList(dataObj:any){
    return this.http.post<any>(`${this.baseUrl}/arcclaimList`, dataObj).pipe(catchError(this.errorHandler))
  }

  // claim list
  getlatLong(dataObj:any){
    return this.http.post<any>(`${this.baseUrl}/getLatLong`, dataObj).pipe(catchError(this.errorHandler))
  }
  // claim Overview
  getOverview(dataObj:any){
    return this.http.post<any>(`${this.baseUrl}/getClaimOverview`, dataObj).pipe(catchError(this.errorHandler))
  }
  
  // Claim History
  getHistory(dataObj:any){
    return this.http.post<any>(`${this.baseUrl}/getClaimHistory`, dataObj).pipe(catchError(this.errorHandler))
  }
  

  //-----------Add Inspection
  addInspection(dataObj:any){
    //console.log(dataObj);
    var formData: any = new FormData();
    formData.append("inspector_id", dataObj.inspector_id);
    formData.append("claimid", dataObj.claimid);
    formData.append("actionid", dataObj.actionid);
    formData.append("filename", dataObj.filename);
    formData.append("file", dataObj.file);
    formData.append("remarks", dataObj.remarks);
    return this.http.post<any>(`${this.baseUrl}/addReport`, formData).pipe(catchError(this.errorHandler))
  }
  
  //-------- Get Inspection
  getInspection(dataObj:any){
    return this.http.post<any>(`${this.baseUrl}/getReportList`, dataObj).pipe(catchError(this.errorHandler))
  }

  //-------- Delete Inspection
  delInspection(dataObj:any){
    return this.http.post<any>(`${this.baseUrl}/inspecDelete`, dataObj).pipe(catchError(this.errorHandler))
  }
 //------download inspection file----
 downloadFile(dataObj:any) {
  return this.http.post(`${this.baseUrl}/download`, dataObj, { responseType: 'blob'})
        .pipe(catchError(this.errorHandler))
  }

  getRejectionList(){
    return this.http.get<any>(`${this.baseUrl}/reasonList`).pipe(catchError(this.errorHandler))
  }

 //---------Claim Submission 
  onClaimSubmission(subObj:any){
    return this.http.post<any>(`${this.baseUrl}/submitClaim`, subObj).pipe(catchError(this.errorHandler))
  }

  getactionList(){
    return this.http.get<any>(`${this.baseUrl}/actionList`).pipe(catchError(this.errorHandler))
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error)
  }
}
