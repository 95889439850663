import { Component, OnInit,  ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/timer";
import "rxjs/add/operator/finally";
import "rxjs/add/operator/takeUntil";
import "rxjs/add/operator/map";
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions } from 'mydatepicker';
import { HelperService } from '../services/helper.service';
import { TaskService } from '../services/task.service';
import { ClaimsService } from '../services/claims.service';
import { from } from 'rxjs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.css']
})
export class ClaimsComponent implements OnInit {

  @ViewChild('closeBtnAcc' , {static: false}) closeBtnAcc: ElementRef;
  @ViewChild('closeBtnRej' , {static: false}) closeBtnRej: ElementRef;
  @ViewChild('submitButtonAcc',{static: false }) submitButtonAcc:ElementRef;
  @ViewChild('submitButtonRej',{static: false }) submitButtonRej:ElementRef;
  submitBtn: boolean = true;
  showTabs:boolean = false;
  isDisabled: boolean = false;
  orderByList: any=[{id:3,label:'Newest',name:'DESC'},{id:2,label:'Oldest',name:'ASC'}];
  claimOverview:any = [];
  claimListData:any = [];
  rejectionStage: any = [];
  actionList: any = [];
  headerDetail: any = [];
  claimHistory:any = [];
  tabItem:number = 0;
  currentPage:number = 1;
  errorMsg='';
  dataCount:any;
  p: number = 1;
  itemsPerPage:number = 10;
  offset:number = 0;
  textfilter:any;
  rejectClaimid: number = 0;
  acceptClaimid: number = 0;
  countdown: number;
  config: any;
  loader: boolean = true;
  loaderSmall: boolean = true;
  inspectorid:any = this.helperService.getInspectorid();
  apiDataObj:any = { inspector_id: this.inspectorid, orderBy: 'DESC', offset: this.offset, rowss: this.itemsPerPage};
  sortType:any;
  rejectionForm: FormGroup;
  acceptForm:  FormGroup;
  constructor(
    private claimsService: ClaimsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private helperService: HelperService,
    private router : Router,
    private route : ActivatedRoute,
    private taskService: TaskService
    ) { 
      //--------Rejection Submit
      this.rejectionForm = this.fb.group({
        rejclaimid: ['',Validators.required],
        rejectionreason: ['', Validators.required],
        remarks: ['']
      })
      //---------Accept Submit
      this.acceptForm = this.fb.group({
        claimid: ['',Validators.required],
        amount: ['', [ Validators.required, Validators.pattern('^[0-9]+(\.[0-9]*){0,1}$')]],
        remarks: ['']
      })

    }
  ngOnInit() {
    this.claimListApi(this.apiDataObj);
    this.inspectorDetail();
    //this.startCountdownTimer();
  }
  private closeModalAcc(): void {
    this.closeBtnAcc.nativeElement.click();
  }
  private closeModalRej(): void {
    this.closeBtnRej.nativeElement.click();
  }
  
  inspectorDetail(){
    this.helperService.inspectorDetail().subscribe( 
      response => {
          if(response.success===true){
              if(response.data.length >0 ) { 
                this.headerDetail = response.data[0].inspectorDetail[0];
              }else {
                this.toastr.success('No More data found! ','No Data',{
                  timeOut: 3000
                })
              }
          }
      },
      error => { 
        this.errorMsg = error.statusText 
      })
  }
  goTaskPage(claimid: number){
     this.router.navigate(['home/task', claimid]);
  }
  /* OnClick of OverView Button open the Overview Tab */
  openOverview(index:any,claimId:any){
      this.tabItem=1;
      this.showTabs=true;
      //Open First Tab
      this.tabSelect(this.tabItem);
  }
  tabSelect(e){
    this.tabItem = e;
  }
  //-----Create Tab & Accordian--
  overviewAccSelect(index:any,flag:any){
   // reset the tab & accordian
   this.tabItem=0;
   this.showTabs = false;
    for(let i=0;i<this.claimListData.length;i++) {
        this.claimListData[i].open = false;
        if(i == index ) {
            if(flag === true){
                this.claimListData[i].open = false;
            }else{
                this.claimListData[i].open = true;
            }
        }
    }
  }
  claimListApi(dataObj:any) {
    this.claimsService.getClaimList(dataObj).subscribe( 
            response => {
              this.claimListData=[];
              this.dataCount = 0;
                if(response.success===true){
                    this.loader = false; 
                    if(response.data.length>0) { 
                      this.claimListData=response.data[0].claimDetail;
                      this.dataCount=response.data[0].rowsCount[0].cnt;
                    }else {
                      this.toastr.success('No More data found! ','No Data',{
                        timeOut: 3000
                      })
                    }
                }
            },
            error => { 
              this.errorMsg = error.statusText 
            })
  }
  //---------Current Page changed-----
   pageChanged(event:any) {
    this.apiDataObj.offset = (event-1)* this.itemsPerPage ;
    this.claimListApi(this.apiDataObj);
 }
  //---------Update Rows-------
  onChange(rowsValue:any) {
    this.itemsPerPage = rowsValue;
    this.apiDataObj.rowss = this.itemsPerPage;
    this.claimListApi(this.apiDataObj);
  }
  //----------Update Order by option
  getSelectedDropdown(itemId:any,sortType:any) {
      for(let i=0;i<this.orderByList.length;i++) {
              this.orderByList[i].active = false;
            if(i === itemId) {
              this.orderByList[i].active = true;
            }
        }
      this.apiDataObj.orderBy = sortType ;
      this.claimListApi(this.apiDataObj);
  } 
  //==================== Tabs Data ===============
 
  //-------------RejectionModal-------
  rejectionModal(claimid:any){
    this.rejectClaimid = claimid;
    this.rejectionForm.get('rejclaimid').setValue(this.rejectClaimid);
    this.rejectionForm.get('rejclaimid').disable({onlySelf: true});
  }

  //-------------RejectionModal-------
  acceptModal(claimid:any){
    this.acceptClaimid = claimid;
    this.acceptForm.get('claimid').setValue(this.acceptClaimid);
    this.acceptForm.get('claimid').disable({onlySelf: true});
  }

  //---------Rejection List------
  getRejectionList(){
    this.claimsService.getRejectionList().subscribe(response=>{
        if(response.success===true){
          if(response.data.length >0) {
            this.rejectionStage = response.data[0].reasonList;
          }
        } else {
           this.errorMsg = response.msg
        }
    },
    error=>{
       this.errorMsg = error.statusText
    })
  }
  //--------get Action List---------//
  getactionList(){
    this.claimsService.getactionList().subscribe(response=>{
        if(response.success===true){
          if(response.data.length >0) {
            this.actionList = response.data[0].actionList;
          }
        } else {
          this.errorMsg = response.msg;
        }
    },
    error=>{
       this.errorMsg = error.statusText
    })
  }
  // convenience getter for easy access to form fields
  get rejectform() { 
    return this.rejectionForm.controls; 
  }
   // convenience getter for easy access to form fields
   get acceptform() { 
    return this.acceptForm.controls; 
  }
  onAcceptSubmit(){
    this.submitButtonAcc.nativeElement.disabled = true;
    let { claimid, remarks, amount} = this.acceptForm.getRawValue();
      claimid = claimid;
      amount = parseInt(amount);
    let acceptObj =  { claimid: claimid, inspectorid: this.inspectorid, actionid: 2, remarks: remarks, amount: amount }
    this.claimsService.submitAcception(acceptObj).subscribe(
      response => {
          if(response.success === true){
              this.submitButtonAcc.nativeElement.disabled = false;
              this.toastr.success(response.msg,'Success',{
                timeOut: 3000
              })
              // Reset Form
              this.acceptForm.reset();
              //----------close modal---
              this.closeModalAcc();
              this.claimListApi(this.apiDataObj);
          }else {
            this.toastr.error(response.msg,'Error',{
              timeOut: 3000
            })
          }
      },
      error => { 
        this.submitButtonAcc.nativeElement.disabled = false;
        this.errorMsg = error.statusText 
      })
  }
  clearInterest(){
    //----------close modal---
    this.closeModalAcc();
  }
  onRejectionSubmit(){
    this.submitButtonRej.nativeElement.disabled = true;
    let { rejclaimid, remarks, rejectionreason } = this.rejectionForm.getRawValue();
    let claimid = rejclaimid;
    rejectionreason = parseInt(rejectionreason);
    let rejectObj = { claimid: claimid, inspectorid: this.inspectorid, actionid: 3, remarks: remarks, reason_stage_id: rejectionreason }
    this.claimsService.submitRejection(rejectObj).subscribe(
      response => {
          if(response.success===true){
              this.submitButtonRej.nativeElement.disabled = false;
              this.toastr.success(response.msg,'Success',{
                timeOut: 3000
              })
              // Reset Form
              this.rejectionForm.reset();
              //----------close modal---
              this.closeModalRej();
              this.claimListApi(this.apiDataObj);
          }else {
            this.toastr.error(response.msg,'Error',{
              timeOut: 3000
            })
          }
      },
      error => { 
        this.submitButtonRej.nativeElement.disabled = false;
        this.errorMsg = error.statusText; 
      })
  }
  //On clear close Modal
  clearReject(){
    this.closeModalRej();
  }

  //------Download Forms File
  download(claimid: any) {
    claimid = claimid;
   let filename = 'Images.zip'; 
   let downloadObj = { inspector_id: this.inspectorid, claimid: claimid };
   this.taskService.downloadFile(downloadObj).subscribe( 
     response => {
        saveAs(response, filename)
     },
     error => { 
       this.errorMsg = error.statusText 
     })
  }
  overviewData(claimid:number){
    this.loaderSmall = true;
    this.claimOverview = [];
    let dataObj={ claimid : claimid };
    this.taskService.getOverview(dataObj).subscribe( 
      response => {
          if(response.success===true){
            this.loaderSmall = false;
              if(response.data.length >0) { 
                this.claimOverview = response.data[0].claimOverview;
              }else {
                this.toastr.success('No More data found! ','No Data',{
                  timeOut: 3000
                })
              }
          }
      },
      error => { 
        this.errorMsg = error.statusText 
      })
  }
  getClaimHistory(claimid:number){
    this.loaderSmall = true;
    this.claimHistory = [];
    let dataObj={ claimid : claimid };
    this.taskService.getHistory(dataObj).subscribe( 
      response => {
          if(response.success===true){
            this.loaderSmall = false;
              if(response.data.length >0) { 
                this.claimHistory = response.data[0].claimHistory;
              }else {
                this.toastr.success('No More data found! ','No Data',{
                  timeOut: 3000
                })
              }
          }
      },
      error => { 
        this.errorMsg = error.statusText 
      })
  }


}
