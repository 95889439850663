import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HelpService {
  baseUrl: any = `${environment.baseUrl}help`;
  constructor(private http: HttpClient) { }
  //-----Reason List
  reasonList(){
    return this.http.get<any>(`${this.baseUrl}/reasonList`).pipe(catchError(this.errorHandler));
  }
  // Faq List 
  faqList(){
    return this.http.get<any>(`${this.baseUrl}/faqsList`).pipe(catchError(this.errorHandler));
  }
   //-----Contact Us
  contactUs(dataObj){
    //console.log(dataObj);
    var formData: any = new FormData();
    formData.append("reason", dataObj.reason);
    formData.append("file", dataObj.file);
    formData.append("remark", dataObj.remark);
    formData.append("inspector_id", dataObj.inspector_id);
    return this.http.post<any>(`${this.baseUrl}/addattach`, formData).pipe(catchError(this.errorHandler))
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error)
  }
}
