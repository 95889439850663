
export const environment = {
  production: false,
  //baseUrl: 'http://10.74.114.50:4002/',
  //baseUrl: 'http://localhost:4002/'
  //baseUrl: 'https://del5wfisuixap01.fnfis.com/MohawkInspector/API/'
  //baseUrl: 'http://qainspections.mohawkind.com:3001/'
  baseUrl: 'https://inspections.mohawkind.com:3001/'
};

// export const environment = {
//   production: false,
//Dev   baseUrl: 'http://10.74.114.50:4002/'
//local   baseUrl: 'http://localhost:4002/'
//QA   baseUrl: 'http://qainspections.mohawkind.com:3001/'
//Prod baseUrl: 'https://inspections.mohawkind.com:3001/'
//baseUrl: 'https://del5wfisuixap01.fnfis.com/',
// };

