import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  baseUrl: any = `${environment.baseUrl}login`;
  constructor(
    private http: HttpClient,
    private router: Router
    ) { }
  verifyInspector(inspectorObj:any){
    return this.http.post<any>(`${this.baseUrl}/userVerify`, inspectorObj).pipe(catchError(this.errorHandler))
  }
  tmppasswordInspector(inspectorObj){
    return this.http.post<any>(`${this.baseUrl}/tmpPassword`, inspectorObj).pipe(catchError(this.errorHandler))
  }
  setpasswordInspector(inspectorObj:any){
    return this.http.post<any>(`${this.baseUrl}/setPassword`, inspectorObj).pipe(catchError(this.errorHandler))
  }
  loginInspector(inspectorObj:any){
    return this.http.post<any>(`${this.baseUrl}/loginUser`, inspectorObj).pipe(catchError(this.errorHandler))
  }
  forgetPassword(inspectorObj:any){
    return this.http.post<any>(`${this.baseUrl}/forgetPassword`, inspectorObj).pipe(catchError(this.errorHandler))
  }
  errorHandler(error: HttpErrorResponse) {
    return throwError(error)
  }
  logOut(){
    localStorage.clear();
    this.router.navigate(['/']);
  }
}
