import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder,AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/login.service';
import { CustomValidators } from '../shared/custom-validators';
import {AuthService} from '../services/auth/auth.service'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('resetPassword',{static: false}) resetPassword:ElementRef;
  errorMsg: string ='';
  submittedEmail: any;
  otpForm: FormGroup;
  passwordsetForm: FormGroup;
  loginForm: FormGroup;
  temppassForm: FormGroup;
  forgetpForm: FormGroup;
  isTemppassword : boolean = false; 
  isFirstTimeUser: boolean =false;
  issetPassword : boolean =false;
  isloginForm: boolean= false;
  isforgetPassword: boolean = false;
  verifyUser : boolean = true;
  warningAlert: boolean = false;
  successAlert: boolean = false;
  loginFrmEmailDisable: boolean = false;
  disableSigninAnchor: boolean = true;
  warningMesg: string;
  successMesg: string;
  constructor(
          private router: Router,
          private loginService: LoginService,
          private fb: FormBuilder,
          private toastr: ToastrService,
          private authService : AuthService
          ) { 
             //-----Initiate Otp Form 
            this.otpForm = this.fb.group({
              email: [null, [Validators.required, Validators.email]]
            })
            //-----Forget Password----
            this.forgetpForm =  this.fb.group({
              email: [null, [Validators.required, Validators.email]]
            })
            //---- Temp Form ----
            
            this.temppassForm = this.fb.group({
              email: [null, [Validators.required, Validators.email]],
              temppassword: [null, [Validators.required]]
            })
            //-----Initiate passwordsetForm
            this.passwordsetForm = this.createSignupForm();
             
            //-----Initaiate Login Form
            this.loginForm = this.fb.group({
              email: [null, [Validators.required, Validators.email]],
              //userotp: ['',[Validators.required]],
              password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(16)]]
            });
    }
  ngOnInit() {
    if(this.authService.isAuthenticated()){
      this.router.navigate(['/home']);
    }
  }
  onResetPasswordBtnClick()
  {
      this.resetPassword.nativeElement.disabled = true;
      this.resetPassword.nativeElement.disabled = false;
  }
  //----Verify User Controls
  get otpF() { 
    return this.otpForm.controls; 
  }
  //---Get Temp Password
  get tpF() { 
    return this.temppassForm.controls; 
  }
  //----Set Password Controls
  get psF() { 
    return this.passwordsetForm.controls; 
  }
  //----Login Form Controls
  get lF() { 
    return this.loginForm.controls; 
  }
  //-----Forget Password
  get fpf(){
    return this.forgetpForm.controls;
  }
  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        email: [
          null,
          Validators.compose([Validators.email, Validators.required])
        ],
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8),
            Validators.maxLength(16),
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }
userVerifySubmit() {
    this.loginService.verifyInspector(this.otpForm.value).subscribe(
      response => {
          if(response.success===true){
            this.submittedEmail =this.otpForm.controls.email.value
              if(response.inspReturnCode ===0){
                  this.warningAlert = true;
                  this.warningMesg = response.msg
                  this.hideWarningAlert();
              } else if(response.inspReturnCode ===1){
                //console.log(this.isFirstTimeUser);
                  this.isTemppassword = true;
                  this.verifyUser = false;
                  this.successMesg = response.msg
                  this.successAlert = true;
                  this.hideSuccessAlert();
                  // set email into set password
                  this.temppassForm.controls.email.setValue(this.submittedEmail);
                  this.otpForm.reset();
              } else if (response.inspReturnCode === 2){
                   // this.isFirstTimeUser = response.isFirstTimeUser;
                    this.verifyUser = false;
                    this.isloginForm = true;
                    this.successMesg = response.msg
                    this.successAlert = true;
                    this.hideSuccessAlert();
                    this.loginFrmEmailDisable = true;
                    this.loginForm.controls.email.setValue(this.submittedEmail);
                    this.otpForm.reset();
              } else {
                this.warningAlert = true;
                this.warningMesg = response.msg
                this.hideWarningAlert();
              }
          }else {
                this.warningAlert = true;
                this.warningMesg = response.msg
                this.hideWarningAlert();
          } 
      },
        error => { this.errorMsg = error.statusText 
      })
  }
  temppassSubmit(){
    this.loginService.tmppasswordInspector(this.temppassForm.value).subscribe(
      response => {
          if(response.success===true){
              if(response.inspReturnCode ===0){
                this.warningAlert = true;
                this.warningMesg = response.msg
                this.hideWarningAlert();
              } else if(response.inspReturnCode ===2){
                    this.warningAlert = true;
                    this.warningMesg = response.msg
                    this.hideWarningAlert();
                    this.temppassForm.reset();
                    this.router.navigate(['/']);
                    this.isTemppassword = false;
                    this.verifyUser = true;
              }
               else if(response.inspReturnCode ===1){
                  this.isTemppassword = false;
                  this.issetPassword = true;
                  this.successMesg = response.msg
                  this.successAlert = true;
                  this.hideSuccessAlert();
                  this.passwordsetForm.controls.email.setValue(this.submittedEmail);
                  this.temppassForm.reset();
              }
          } else {
            this.warningAlert = true;
            this.warningMesg = response.msg
            this.hideWarningAlert();
          }
      },
        error => { this.errorMsg = error.statusText 
      })
  }

  setpasswordSubmit(){
    this.warningAlert = false;
    this.loginService.setpasswordInspector(this.passwordsetForm.value).subscribe(
      response => {
          if(response.success===true){
              if(response.inspReturnCode ===0){
                  this.warningAlert = true;
                  this.warningMesg = response.msg
                  this.hideWarningAlert();
                  this.isFirstTimeUser = response.isFirstTimeUser;
              } else if(response.inspReturnCode ===1){
                  this.isFirstTimeUser = response.isFirstTimeUser;
                  this.issetPassword =false;
                  this.successMesg = response.msg
                  this.successAlert = true;
                  this.hideSuccessAlert();
                  this.verifyUser = true;
                  this.passwordsetForm.reset();
                  this.router.navigate(['/login']);
              }
          } else {
            this.warningAlert = true;
            this.warningMesg = response.msg
            this.hideWarningAlert();
          }
      },
        error => { this.errorMsg = error.statusText 
      })
  }
loginSubmit(){
    this.submittedEmail = this.loginForm.value.email;
    this.loginService.loginInspector(this.loginForm.value).subscribe(
      response => {
          if(response.success===true){
              if(response.inspReturnCode ===0){
                  this.warningAlert = true;
                  this.warningMesg = response.msg
                  this.hideWarningAlert();
              } else if(response.inspReturnCode ===1){
                  this.successMesg = response.msg
                  this.successAlert = true;
                  this.hideSuccessAlert();
                  localStorage.setItem('token', response.token);
                  this.loginFrmEmailDisable = false; 
                  this.loginForm.reset();
                  this.router.navigate(['/home']);
              } else if(response.inspReturnCode === 2){
                    this.warningAlert = true;
                    this.warningMesg = response.msg
                    this.loginForm.controls.password.setValue('');
                    this.loginForm.controls.password.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(16)]);
                    this.loginForm.controls.password.updateValueAndValidity()
                    this.hideWarningAlert();
                    this.isloginForm = false;
                    this.verifyUser = true;
              }
              else if(response.inspReturnCode === 4){
                this.warningAlert = true;
                this.warningMesg = response.msg
                this.loginForm.controls.password.setValue('');
                this.loginForm.controls.password.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(16)]);
                this.loginForm.controls.password.updateValueAndValidity()
                this.hideWarningAlert();
                // this.isloginForm = false;
                // this.verifyUser = true;
                // this.otpForm.controls.email.setValue(this.submittedEmail);
           }
          } 
          else {
            this.warningAlert = true;
            this.warningMesg = response.msg
            this.hideWarningAlert();
          }
      },
      error => { this.errorMsg = error.statusText 
      })
    }
  //--------Forget Password----
  forgetpasswordReset(){
    this.verifyUser = false;
    this.isforgetPassword = true; 
  }
  forgetPasswordOnLogin(){
    this.isloginForm = false;
    this.loginForm.reset();
    this.isforgetPassword = true;
  }
  submitForgetPassword(){
    this.submittedEmail = this.forgetpForm.value.email;
    this.loginService.forgetPassword(this.forgetpForm.value).subscribe(
      response => {
          if(response.success===true){
              if(response.inspReturnCode ===0){
                  this.warningAlert = true;
                  this.warningMesg = response.msg
                  this.hideWarningAlert();
              } else if(response.inspReturnCode ===2){
                this.warningAlert = true;
                this.warningMesg = response.msg
                this.hideWarningAlert();
              }  
              else if(response.inspReturnCode ===1){
                  this.successMesg = response.msg
                  this.successAlert = true;
                  this.hideSuccessAlert();
                  this.isTemppassword = true;
                  this.isloginForm = false;
                  this.isforgetPassword = false;
                  this.temppassForm.controls.email.setValue(this.submittedEmail);
              }
          } else {
            this.toastr.warning( response.msg,'Warning',{
              timeOut: 3000
            })
          }
      },
        error => { this.errorMsg = error.statusText 
      })
  }
  hideWarningAlert(){
    setTimeout(()=>{
      this.warningAlert = false; 
    },
    4000);  
  } 
  hideSuccessAlert(){
    setTimeout(()=>{
      this.successAlert = false; 
    },
    3000);  
  }
}
