import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TaskService } from '../services/task.service';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions } from 'mydatepicker';
import { HelperService } from '../services/helper.service';
import { datesValidator } from '../shared/datesValidator';
import { ArchiveService } from '../services/archive.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {
  @ViewChild('closeBtn', { static: false }) closeBtn: ElementRef;
  @ViewChild('closeBtnSch', { static: false }) closeBtnSch: ElementRef;
  @ViewChild('closeBtnConfirm', { static: false }) closeBtnConfirm: ElementRef;
  @ViewChild('closeBtnCancel', { static: false }) closeBtnCancel: ElementRef;
  @ViewChild('submitInsButton', { static: false }) submitInsButton: ElementRef;
  @ViewChild('scheduleSbmtBtn', { static: false }) scheduleSbmtBtn: ElementRef;
  activeTab: boolean = false;
  showTabs: boolean = false;
  activeOrderByLink: boolean = false;
  dateRangeFlag: boolean = false;
  loaderSmall: boolean = true;
  orderByList: any = [{ id: 3, label: 'Newest', name: 'DESC' }, { id: 2, label: 'Oldest', name: 'ASC' }];
  browseFilename: any;
  claimOverview: any = [];
  waypoints: any = [];
  //------show Customer info and Disable Button(submit and schedule)
  customerInfosubAndSchBtn: boolean = false;
  showCustomerDetailArr: any = [6, 11, 12, 13, 14, 15, 16, 17];
  claimHistory: any = [];
  claimListData: any = [];
  commentList: any = [];
  inspectionList: any = [];
  rejectionStage: any = [];
  dirs: any = [];
  dirArray: any = [];
  actionList: any = [];
  headerDetail: any = [];
  preview: string;
  tabItem: number = 0;
  claimIdDel: any;
  inspectionId: any;
  claimId: number;
  p: number = 1;
  errorMsg = '';
  dataCount: any;
  itemsPerPage: number = 10;
  offset: number = 0;
  textfilter: any;
  config: any;
  fileObj: any;
  inspectorid: any = this.helperService.getInspectorid();
  apiDataObj: any = { inspector_id: this.inspectorid, orderBy: 'DESC', offset: this.offset, rowss: this.itemsPerPage };
  sortType: any;
  loader: boolean = true;
  inspectionForm: FormGroup;
  scheduleForm: FormGroup;
  commentForm: FormGroup;
  constructor(
    private taskService: TaskService,
    private archiveService: ArchiveService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private helperService: HelperService,
    private route: ActivatedRoute
  ) {

    //---------Schedule Form-----//
    this.scheduleForm = this.fb.group({
      schclaimid: ['', Validators.required],
      dateOpt: ['1', Validators.required],
      schedulestage: ['', Validators.required],
      startDate: [''],
      endDate: [''],
      time: ['']
    })
    //},{validator: datesValidator('startDate','endDate') })
    //-----Initiate Inspection form 
    this.inspectionForm = this.fb.group({
      filename: ['', Validators.required],
      file: ['', Validators.required],
      remarks: ['', Validators.required]
    })
    //-----Commnet Form ----
    this.commentForm = this.fb.group({
      comment: ['', Validators.required]
    })

  }

  ngOnInit() {
    this.inspectorDetail();
    //-------Claim Id Route-----//
    this.route.params.subscribe(params => {
      this.claimId = params['claimId'];
    });
    if (this.claimId) {
      let dataObj = { inspector_id: this.inspectorid, claimid: this.claimId };
      this.claimListById(dataObj);
    } else {
      this.claimListApi(this.apiDataObj);
    }

  }

  //-----Get Inspection Form Control-----
  get insForm() {
    return this.inspectionForm.controls;
  }
  //-----Get Inspection Form Control-----
  get schedForm() {
    return this.scheduleForm.controls;
  }
  //-------Get Commnet Form Control-----
  get cmtForm() {
    return this.commentForm.controls;
  }
  public mytime: Date = new Date();
  currentYear: any = this.mytime.getUTCFullYear();
  currentDate: any = this.mytime.getUTCDate();
  currentMonth: any = this.mytime.getUTCMonth() + 1; //months from 1-12
  public myDatePickerOptions: IMyDpOptions = {
    disableUntil: { year: this.currentYear, month: this.currentMonth, day: this.currentDate - 1 },
    dateFormat: 'yyyy-mm-dd ',
  };
  public closeModal(): void {
    this.closeBtn.nativeElement.click();
  }
  public closeModalSch(): void {
    this.closeBtnSch.nativeElement.click();
  }
  public closeConfirm(): void {
    this.closeBtnConfirm.nativeElement.click();
  }
  public markerOptions = {
    origin: {
      icon: ''
      // draggable: true,
    },
    destination: {
      icon: ''
      //label: 'MARKER LABEL',
      //opacity: 0.8,
    },
  }
  dateSelectType(radioType: number) {
    this.scheduleForm.controls['startDate'].reset();
    this.scheduleForm.controls['endDate'].reset();
    if (radioType === 0) {
      this.dateRangeFlag = true;
      this.scheduleForm.controls['startDate'].setValidators([Validators.required]);
      this.scheduleForm.controls['startDate'].updateValueAndValidity();
      this.scheduleForm.controls['endDate'].setValidators([Validators.required]);
      this.scheduleForm.controls['endDate'].updateValueAndValidity();
    } else {
      this.dateRangeFlag = false;
      this.scheduleForm.controls['startDate'].setValidators([Validators.required]);
      this.scheduleForm.controls['startDate'].updateValueAndValidity();
      this.scheduleForm.controls['endDate'].setValidators([]);
      this.scheduleForm.controls['endDate'].updateValueAndValidity();
    }
  }
  inspectorDetail() {
    this.helperService.inspectorDetail().subscribe(
      response => {
        if (response.success === true) {
          if (response.data.length > 0) {
            this.headerDetail = response.data[0].inspectorDetail[0];
          } else {
            this.toastr.success('No More data found! ', 'No Data', {
              timeOut: 3000
            })
          }
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  claimListById(dataObj: any) {
    this.taskService.getClaimListById(dataObj).subscribe(
      response => {
        this.claimListData = [];
        if (response.success === true) {
          if (response.data.length > 0) {
            this.claimListData = response.data[0].claimDetail;
            this.dataCount = response.data[0].rowsCount[0].cnt;
          } else {
            this.toastr.success('No More data found! ', 'No Data', {
              timeOut: 3000
            })
          }
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  /* OnClick of OverView Button open the Overview Tab */
  openOverview(index: any, claimId: any) {
    this.tabItem = 1;
    this.showTabs = true;
    //Open First Tab
    this.tabSelect(this.tabItem);
  }
  tabSelect(e) {
    this.tabItem = e;
  }

  //-----Create Tab & Accordian--
  overviewAccSelect(index: any, flag: any) {
    // reset the tab & accordian
    this.tabItem = 0;
    this.showTabs = false;
    for (let i = 0; i < this.claimListData.length; i++) {
      this.claimListData[i].open = false;
      if (i == index) {
        if (flag === true) {
          this.claimListData[i].open = false;
        } else {
          this.claimListData[i].open = true;
        }
      }
    }
  }
  showCustomerDetail(stageId: number) {
    if (this.showCustomerDetailArr.includes(stageId)) {
      this.customerInfosubAndSchBtn = true;
    } else {
      this.customerInfosubAndSchBtn = false;
    }
  }
  claimListApi(dataObj: any) {
    this.taskService.getClaimList(dataObj).subscribe(
      response => {
        this.claimListData = [];
        this.dataCount = 0;
        if (response.success === true) {
          this.loader = false;
          if (response.data.length > 0) {
            this.claimListData = response.data[0].claimDetail;
            this.dataCount = response.data[0].rowsCount[0].cnt;
          } else {
            this.toastr.success('No More data found! ', 'No Data', {
              timeOut: 3000
            })
          }
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }

  //---------Current Page changed-----
  pageChanged(event) {
    this.apiDataObj.offset = (event - 1) * this.itemsPerPage;
    this.claimListApi(this.apiDataObj);
  }
  //---------Update Rows-------
  onChange(rowsValue: any) {
    this.itemsPerPage = rowsValue;
    this.apiDataObj.rowss = this.itemsPerPage;
    this.claimListApi(this.apiDataObj);
  }
  //----------Update Order by option
  getSelectedDropdown(itemId: any, sortType: any) {
    for (let i = 0; i < this.orderByList.length; i++) {
      this.orderByList[i].active = false;
      if (i === itemId) {
        this.orderByList[i].active = true;
      }
    }
    this.apiDataObj.orderBy = sortType;
    this.claimListApi(this.apiDataObj);
  }
  //==================== Tabs Data ===============
  overviewData(claimid: number) {
    this.loaderSmall = true;
    this.claimOverview = [];
    let dataObj = { claimid: claimid };
    this.taskService.getOverview(dataObj).subscribe(
      response => {
        if (response.success === true) {
          this.loaderSmall = false;
          if (response.data.length > 0) {
            this.claimOverview = response.data[0].claimOverview;
          } else {
            this.toastr.success('No More data found! ', 'No Data', {
              timeOut: 3000
            })
          }
        }
      },
      error => {
        this.loaderSmall = false;
        this.errorMsg = error.statusText
      })
  }
  mapView(claimid: any) {
    this.dirArray = [];
    claimid = claimid;
    let mapObj = { claimid: claimid, inspectorid: this.inspectorid };
    this.taskService.getlatLong(mapObj).subscribe(
      response => {
        if (response.success === true) {

          if (response.data.length > 0) {
            let latLongArr = response.data[0].latlongList;
            for (let i = 0; i < latLongArr.length; i++) {
              let dirObj = {};
              // let locationObj = {};
              // locationObj['location'] = { lat: latLongArr[i].inslat, lng: latLongArr[i].inslong };
              //   this.waypoints.push(locationObj);
              // locationObj['location'] = { lat: latLongArr[i].custlat, lng: latLongArr[i].custlong };
              //   this.waypoints.push(locationObj);
              dirObj['origin'] = { lat: latLongArr[i].inslat, lng: latLongArr[i].inslong };
              dirObj['destination'] = { lat: latLongArr[i].custlat, lng: latLongArr[i].custlong };
              dirObj['renderOptions'] = { polylineOptions: { strokeColor: '#920000', strokeOpacity: 1, strokeWeight: 4, fillColor: '#706bd6' } };
              this.dirs.push(dirObj);
            }
            //  console.log(this.dirs)

          } else {
            this.toastr.success('No More data found! ', 'No Data', {
              timeOut: 3000
            })
          }
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }

  getClaimHistory(claimid: number) {
    this.loaderSmall = true;
    this.claimHistory = [];
    let dataObj = { claimid: claimid };
    this.taskService.getHistory(dataObj).subscribe(
      response => {
        if (response.success === true) {
          this.loaderSmall = false;
          if (response.data.length > 0) {
            this.claimHistory = response.data[0].claimHistory;
          } else {
            this.toastr.success('No More data found! ', 'No Data', {
              timeOut: 3000
            })
          }
        }
      },
      error => {
        this.loaderSmall = false;
        this.errorMsg = error.statusText
      })
  }

  //------Inspection List
  getInspection(claimid: number) {
    //this.loaderSmall = true;
    this.inspectionList = [];
    let dataObj = { claimid: claimid };
    this.taskService.getInspection(dataObj).subscribe(
      response => {
        if (response.success === true) {
          //this.loaderSmall = false;
          if (response.data.length > 0) {
            this.inspectionList = response.data[0].reportList;
            // console.log(this.inspectionList);
          } else {
            this.toastr.success('No data found! ', 'No Data', {
              timeOut: 3000
            })
          }
        }
      },
      error => {
        //this.loaderSmall = false;
        this.errorMsg = error.statusText
      })
  }
  public closeDelModal(): void {
    this.closeBtnCancel.nativeElement.click();
  }
  inspectionDelDetail(inspectionId: number, claimIdDel: any) {
    this.claimIdDel = claimIdDel;
    this.inspectionId = inspectionId;
  }
  clickDelConfirmation(args: any) {
    if (args == 1) {
      this.deleteInspection(this.inspectionId, this.claimIdDel);
    } else {
      this.closeDelModal();
    }
  }
  //------Inspection List
  deleteInspection(inspectionId: number, claimIdDel: any) {
    let dataObj = { inspectionreportid: inspectionId };
    this.taskService.delInspection(dataObj).subscribe(
      response => {
        if (response.success === true) {
          this.getInspection(claimIdDel);
          this.closeDelModal();
          this.toastr.success(response.msg, 'Deleted', {
            timeOut: 3000
          })
        } else {
          this.toastr.error(response.msg, 'Error', {
            timeOut: 3000
          })
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  // download(inspectionid: number,claimid: any, fileName: any) {
  //   claimid = parseInt(claimid);
  //   let filename = fileName;
  //   let downloadObj = { inspector_id: this.inspectorid, claimid: claimid, inspectionreportid: inspectionid };
  //   this.taskService.downloadFile(downloadObj).subscribe( 
  //     response => {
  //        saveAs(response, filename)
  //     },
  //     error => { 
  //       this.errorMsg = error.statusText 
  //     })
  // }

  // // Image Preview
  // uploadFile(event) {
  //     const file = (event.target as HTMLInputElement).files[0];
  //     this.browseFilename = file.name;
  //     this.inspectionForm.patchValue({
  //       file: file
  //     });
  //     this.inspectionForm.get('file').updateValueAndValidity()
  //     // File Preview
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //         this.preview = reader.result as string;
  //     }
  //     reader.readAsDataURL(file)
  // }
  // Image Preview
  uploadFile(event: any) {
    const file = (event.target as HTMLInputElement).files[0];
    this.browseFilename = file.name;
    let filename = file.name;
    //----Check Double Extension---
    let checkDoubleExtn = filename.split('.');
    let dbchkLen = checkDoubleExtn.length;
    //let fileExt = filename.substring(filename.lastIndexOf('.')+1);
    //let extArr = ['txt','pdf','csv','doc','dot','docx','docm','dotx','dotm'];
    // if(!extArr.includes(fileExt.toLowerCase())){
    //   this.toastr.warning('Please enter valid file format','Warning',{
    //     timeOut: 3000
    //   })
    //   this.browseFilename = null;
    //   return;
    // }else 
    if (dbchkLen > 2) {
      this.toastr.warning('Double extensions not allowed', 'Warning', {
        timeOut: 3000
      })
      this.browseFilename = null;
      return;
    } else {
      //console.log('------');
      this.inspectionForm.patchValue({
        file: file
      });
      this.inspectionForm.get('file').updateValueAndValidity()
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.preview = reader.result as string;
      }
      reader.readAsDataURL(file)
    }
  }
  inspecSubmit(claimid: any) {
    this.submitInsButton.nativeElement.disabled = true;
    const { filename, file, remarks } = this.inspectionForm.getRawValue();
    claimid = claimid;
    let insObj = { filename: filename, file: file, remarks: remarks, inspector_id: this.inspectorid, claimid: claimid };
    //console.log(insObj);
    this.taskService.addInspection(insObj).subscribe(
      response => {
        if (response.success === true) {
          this.submitInsButton.nativeElement.disabled = false;
          this.toastr.success(response.msg, response.msg, {
            timeOut: 3000
          })
          // Reset Form
          this.inspectionForm.reset();
          //--- Keep Dafault Values after reset
          this.inspectionForm.patchValue({
            file: null,
          });
          this.browseFilename = null;
          this.getInspection(claimid);
        } else {
          this.toastr.error(response.msg, 'Error', {
            timeOut: 3000
          })
        }
      },
      error => {
        this.submitInsButton.nativeElement.disabled = false;
        this.errorMsg = error.statusText
      })
  }
  //------------Schedule Modal--------
  scheduleModal(claimid: any) {
    this.scheduleForm.get('schclaimid').setValue(claimid);
    this.scheduleForm.get('schclaimid').disable({ onlySelf: true });
    this.scheduleForm.get('schedulestage').setValue(8);
  }
  submitModal(claimid: any) {
    this.claimId = claimid;
  }


  //--------get Action List---------//
  getactionList() {
    this.actionList = [];
    this.taskService.getactionList().subscribe(response => {
      if (response.success === true) {
        this.actionList = response.data[0].actionList;
      } else {
        this.errorMsg = response.msg;
      }
    },
      error => {
        this.errorMsg = error.statusText
      })
  }
  //-------clear form
  cancelBtnSchedule() {
    let optVal = this.scheduleForm.get('dateOpt').value;
    this.scheduleForm.controls['startDate'].reset();
    this.scheduleForm.controls['endDate'].reset();
    if (optVal === 0) {
      this.dateRangeFlag = true;
      this.scheduleForm.controls['startDate'].setValidators([Validators.required]);
      this.scheduleForm.controls['startDate'].updateValueAndValidity();
      this.scheduleForm.controls['endDate'].setValidators([Validators.required]);
      this.scheduleForm.controls['endDate'].updateValueAndValidity();
    } else {
      this.dateRangeFlag = false;
      this.scheduleForm.get('dateOpt').setValue('1');
      this.scheduleForm.controls['startDate'].setValidators([Validators.required]);
      this.scheduleForm.controls['startDate'].updateValueAndValidity();
      this.scheduleForm.controls['endDate'].setValidators([]);
      this.scheduleForm.controls['endDate'].updateValueAndValidity();
    }
  }
  onScheduleSubmit() {
    this.scheduleSbmtBtn.nativeElement.disabled = true;

    let { schclaimid, startDate, endDate, time } = this.scheduleForm.getRawValue();
  

    if (startDate.epoc > endDate.epoc) {
      this.toastr.warning('Start date should be equal or less than the end date', 'Warning', { timeOut: 3000 })
      this.scheduleSbmtBtn.nativeElement.disabled = false;
      return
    } else {
      let startDateFormatted = `${startDate.formatted} 00:00:00`;
      let endDateFormatted;
      if (endDate.formatted === undefined) {
        endDateFormatted = `0000-00-00 00:00:00`;
      } else {
        endDateFormatted = `${endDate.formatted} 00:00:00`;
      }
      schclaimid = schclaimid;
      let scheduleObj = { claimid: schclaimid, inspectorid: this.inspectorid, startdate: startDateFormatted, enddate: endDateFormatted, actionid: 9, time: time }
      this.taskService.scheduleInspection(scheduleObj).subscribe(
        response => {
          if (response.success === true) {
            this.scheduleSbmtBtn.nativeElement.disabled = false;
            this.toastr.success(response.msg, 'Success', {
              timeOut: 3000
            })
            // Reset Form
            this.scheduleForm.reset();
            this.scheduleForm.get('dateOpt').setValue('1');
            this.claimListApi(this.apiDataObj);
            this.closeModalSch();
          } else {
            this.toastr.error(response.msg, 'Error', {
              timeOut: 3000
            })
          }
        },
        error => {
          this.scheduleSbmtBtn.nativeElement.disabled = false;
          this.errorMsg = error.statusText
        })
    }

  }
  //-------------Final Submit-------
  clickConfirmation() {
    let claimid: number = this.claimId;
    claimid = claimid;
    let confirmObj = { inspectorid: this.inspectorid, claimid: claimid, actionid: 11 };
    this.taskService.finalSubmit(confirmObj).subscribe(
      response => {
        if (response.success === true) {
          this.toastr.success(response.msg, 'Success', {
            timeOut: 3000
          })
          // Reset Form
          this.claimListApi(this.apiDataObj);
          this.closeConfirm();
        } else if (response.success === false) {
          if (response.auth === 0) {
            this.toastr.error(response.msg, 'Error', {
              timeOut: 3000
            })
          } else {
            this.toastr.warning(response.msg, 'Warning', {
              timeOut: 3000
            })
            this.closeConfirm();
          }
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  commentSubmit(claimid: any) {
    let { comment } = this.commentForm.value;
    claimid = claimid;
    let commentObj = { inspectorid: this.inspectorid, claimid: claimid, remarks: comment };
    this.taskService.addComment(commentObj).subscribe(
      response => {
        if (response.success === true) {
          this.toastr.success(response.msg, 'Success', {
            timeOut: 3000
          })
          // Reset Form
          this.commentForm.reset();
          this.getComment(this.claimId);
        } else {
          this.toastr.error(response.msg, 'Error', {
            timeOut: 3000
          })
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  //-------Get Comment----
  getComment(claimid: any) {
    this.loaderSmall = true;
    claimid = claimid;
    this.claimId = claimid;
    let getcommentObj = { inspectorid: this.inspectorid, claimid: claimid };
    this.taskService.getComment(getcommentObj).subscribe(
      response => {
        if (response.success === true) {
          this.loaderSmall = false;
          this.commentList = response.data[0].commentList;
        } else {
          this.toastr.error(response.msg, 'Error', {
            timeOut: 3000
          })
        }
      },
      error => {
        this.loaderSmall = false;
        this.errorMsg = error.statusText
      })
  }
  //------Download Forms File
  download(claimid: any) {
    claimid = claimid;
    let filename = 'Images.zip';
    let downloadObj = { inspector_id: this.inspectorid, claimid: claimid };
    this.taskService.downloadFile(downloadObj).subscribe(
      response => {
        saveAs(response, filename)
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
}
