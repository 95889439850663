import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  baseUrl: any = `${environment.baseUrl}claims`;
  constructor(private http: HttpClient) { }
  // claim list
  getClaimList(dataObj:any){
    return this.http.post<any>(`${this.baseUrl}/claimList`, dataObj).pipe(catchError(this.errorHandler))
  }

  getRejectionList(){
    return this.http.get<any>(`${this.baseUrl}/reasonList`).pipe(catchError(this.errorHandler))
  }
 
  submitAcception(accObj:any){
    return this.http.post<any>(`${this.baseUrl}/acceptClaim`, accObj).pipe(catchError(this.errorHandler))
  }

  submitRejection(accObj:any){
    return this.http.post<any>(`${this.baseUrl}/addRejection`, accObj).pipe(catchError(this.errorHandler))
  }


  getactionList(){
    return this.http.get<any>(`${this.baseUrl}/actionList`).pipe(catchError(this.errorHandler))
  }
  errorHandler(error: HttpErrorResponse) {
    return throwError(error)
  }
}
