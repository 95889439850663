import { Component, OnInit, SecurityContext, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '../services/profile.service';
import { HomeService } from '../services/home.service';
import { HelperService } from '../services/helper.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @ViewChild('closeEditProf', { static: false }) closeEditProf: ElementRef;
  @ViewChild('closeEditAddInfo', { static: false }) closeEditAddInfo: ElementRef;
  errorMsg: any;
  editprofile = true;
  workingDetailData: any = [];
  inspectorDetailData: any = [];
  companyList: any = [];
  countryList: any = [];
  cityList: any = [];
  stateList: any = [];
  zipList: any = [];
  moreAddtnlInfo: any = [];
  editProfileData: any;
  fileValidation: boolean = true;
  showmoreAddinfoTab: boolean = false;
  inspectorid: any = this.helperService.getInspectorid();
  preview: any = '../../assets/img/profile.png';
  tabItem: number = 1;
  inspectoraddinfo: any;
  loader: boolean = true;
  profileForm: FormGroup;
  editProfileForm: FormGroup;
  editAdditionalForm: FormGroup;
  isenable: boolean = false;
  constructor(
    private homeService: HomeService,
    private profileService: ProfileService,
    private toastr: ToastrService,
    private helperService: HelperService,
    private _sanitizer: DomSanitizer,
    private fb: FormBuilder
  ) {

  }
  ngOnInit() {
    let inputObj = { inspector_id: this.inspectorid };
    this.workingDetail(inputObj);
    this.inspectorDetail(inputObj);
    this.getProfileImage();
    this.getAddInfo();
    this.additionalDetail(inputObj);
    this.profileForm = this.fb.group({
      file: ['', Validators.required]
    });
    //--------On Edit Profile----
    this.companyListApi();
    this.countryListApi();
    this.editProfileForm = this.fb.group({
       inspectorfname: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
       inspectorlname: ['', Validators.required],
       companyid: ['', Validators.required],
       joinedon: ['', Validators.required],
       address: ['', Validators.required],
       country: ['', Validators.required],
       city: ['', Validators.required],
       inspectorphno: ['', Validators.required],
       state: ['', Validators.required],
       inspectoraddphno: ['', ''],
       zip: ['', Validators.required],
       inspectoremail: ['', [Validators.required, Validators.email]]
    });
    //----Edit Additional Info----
    this.editAdditionalForm = this.fb.group({
      inspectoraddinfo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(150)]]
    })
  }
  tabSelect(e) {
    this.tabItem = e;
    if (e == 2) {
      this.editprofile = false
    }
    else {
      this.editprofile = true;
    }
  }

  get prfForm() {
    return this.profileForm.controls;
  }
  get editPForm() {
    return this.editProfileForm.controls;
  }
  get editAddForm() {
    return this.editAdditionalForm.controls;
  }
  public closeModalEditProf(): void {
    this.editProfileForm.reset();
    this.closeEditProf.nativeElement.click();
  }
  public closeModalEditAddInfo(): void {
    this.closeEditAddInfo.nativeElement.click();
  }
  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    let filetypeArr = file.type.split('/');
    let fileext = filetypeArr[1];
    if (fileext === 'jpeg' || fileext === 'jpg' || fileext === 'png' || fileext === 'PNG') {
      this.profileForm.patchValue({
        file: file
      });
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.preview = reader.result as string;
        let imageObj = { inspectorid: this.inspectorid, profile_image: this.preview };
        this.uploadProfileImage(imageObj);
      }
      reader.readAsDataURL(file)
    } else {
      this.fileValidation = false;
    }

  }
  inspectorDetail(Obj) {
    this.homeService.inspectorDetail(Obj).subscribe(
      response => {
        if (response.success) {
          this.loader = false;
          this.inspectorDetailData = [];
          if (response.data.length > 0) {
            this.inspectorDetailData = response.data[0].inspectorDetail;
          }
        } else {
          this.errorMsg = response.msg;
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  workingDetail(Obj: any) {
    this.profileService.workingDetail(Obj).subscribe(
      response => {
        if (response.success) {
          this.workingDetailData = [];
          if (response.data.length > 0) {
            this.workingDetailData = response.data[0].workingDetail;
          }
        } else {
          this.errorMsg = response.msg;
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  uploadProfileImage(imgObj: any) {
    this.profileService.uploadInsImage(imgObj).subscribe(
      response => {
        if (response.success === true) {
          this.toastr.success(response.msg, 'Success', {
            timeOut: 3000
          });
          this.getProfileImage();
        } else {
          this.errorMsg = response.msg;
          this.toastr.success(response.msg, 'Error', {
            timeOut: 3000
          })
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  getProfileImage() {
    let getimageObj = { inspectorid: this.inspectorid }
    this.profileService.getProfileImage(getimageObj).subscribe(
      response => {
        if (response.success === true) {
          this.preview = ' ';
          if (response.data.length > 0) {
            this.preview = response.data[0].profileImage[0].profile_image;
          }
        } else {
          this.errorMsg = response.msg;
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  public get imageProperty(): SafeHtml {
    if (this.preview === null) {
      this.preview = '../../assets/img/profile.png'
      return this.preview;
    } else {
      return this._sanitizer.sanitize(SecurityContext.HTML, this.preview);
    }
  }
  //=============Edit Profile==============
  onEdit() {
    let insObj = { inspectorid: this.inspectorid }
    this.profileService.editProfileData(insObj).subscribe(
      response => {
        if (response.success === true) {
          this.editProfileData = [];
          if (response.data.length > 0) {
            this.editProfileData = response.data[0].editInspectorDetail[0];
            let joinedon = this.editProfileData.joinedon.slice(0, 12);
            //-------get Country List
            let countryid = this.editProfileData.country;
            let cointryObj = { countryid: countryid };
            this.stateListApi(cointryObj);
            //-------get State List
            let stateid = this.editProfileData.state;
            let stateObj = { stateid: stateid };
            this.cityListApi(stateObj);
            //-------get Zip List
            let cityid = this.editProfileData.city;
            let cityObj = { cityid: cityid };
            this.zipListApi(cityObj);
            this.editProfileData.joinedon = joinedon;
            //----------Update Value to Edit Modal
            this.editProfileForm.setValue(this.editProfileData);
            
          }
        } else {
          this.errorMsg = response.msg;
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  //------CompanyList ------
  companyListApi() {
    this.profileService.companyList().subscribe(
      response => {
        if (response.success === true) {
          this.companyList = [];
          if (response.data.length > 0) {
            this.companyList = response.data[0].companyList;
          }
        } else {
          this.errorMsg = response.msg;
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  //------CountryList ------
  countryListApi() {
    this.profileService.countryList().subscribe(
      response => {
        if (response.success === true) {
          this.countryList = [];
          if (response.data.length > 0) {
            this.countryList = response.data[0].countryList;
          }
        } else {
          this.errorMsg = response.msg;
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  //------StateList ------
  stateListApi(stateObj: any) {
    this.profileService.stateList(stateObj).subscribe(
      response => {
        if (response.success === true) {
          this.stateList = [];
          if (response.data.length > 0) {
            this.stateList = response.data[0].stateList;
          }
        } else {
          this.errorMsg = response.msg;
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  //------cityList ------
  cityListApi(cityObj: any) {
    this.profileService.cityList(cityObj).subscribe(
      response => {
        if (response.success === true) {
          this.cityList = [];
          if (response.data.length > 0) {
            this.cityList = response.data[0].cityList;
          }
        } else {
          this.errorMsg = response.msg;
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  //-------Zip List----
  zipListApi(zipObj: any) {
    this.profileService.zipList(zipObj).subscribe(
      response => {
        if (response.success === true) {
          this.zipList = [];
          if (response.data.length > 0) {
            this.zipList = response.data[0].zipList;
          }
        } else {
          this.errorMsg = response.msg;
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  onStateChange(stateid: any) {
    let stateObj = { stateid: stateid };
    this.cityListApi(stateObj);
  }
  onCityChange(cityid: any) {
    let cityObj = { cityid: cityid };
    this.zipListApi(cityObj);
  }
  onEditSubmit() {
    // console.log(this.editProfileForm.value);
    let updateObj = this.editProfileForm.value;
    updateObj.inspectorid = this.inspectorid;
    this.profileService.updateProfile(updateObj).subscribe(
      response => {
        if (response.success === true) {
          let inspObj = { inspector_id: this.inspectorid };
          this.inspectorDetail(inspObj);
          this.toastr.success(response.msg, 'Success', {
            timeOut: 3000
          });
          this.editProfileForm.reset();
          this.closeModalEditProf();
        } else {
          this.errorMsg = response.msg;
          this.toastr.success(response.msg, 'Error', {
            timeOut: 3000
          })
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  onEditAddInfo() {
    //console.log(this.inspectoraddinfo)
    this.editAdditionalForm.setValue({ inspectoraddinfo: this.inspectoraddinfo });
  }
  onAdditionalSubmit() {
    let updateAddinfoObj = this.editAdditionalForm.value;
    updateAddinfoObj.inspectorid = this.inspectorid;
    //console.log(updateAddinfoObj);
    this.profileService.updateAddInfo(updateAddinfoObj).subscribe(
      response => {
        if (response.success === true) {
          this.toastr.success(response.msg, 'Success', {
            timeOut: 3000
          });
          this.editAdditionalForm.reset();
          this.getAddInfo();
          this.closeModalEditAddInfo();
        } else {
          this.errorMsg = response.msg;
          this.toastr.success(response.msg, 'Error', {
            timeOut: 3000
          })
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  getAddInfo() {
    let inspObj = { inspectorid: this.inspectorid };
    this.profileService.getAddInfo(inspObj).subscribe(
      response => {
        if (response.success === true) {
          if (response.data.length > 0) {
            this.inspectoraddinfo = response.data[0].inspectoraddinfo[0].inspectoraddinfo;
          }
        } else {
          this.errorMsg = response.msg;
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  additionalDetail(Obj: any) {
    this.profileService.additionalDetail(Obj).subscribe(
      response => {
        if (response.success) {
          this.moreAddtnlInfo = [];
          if (response.data.length > 0) {
            let resDataObj = response.data[0].additionalDetail[0];
            for (var key in resDataObj) {
              if (resDataObj[key] !== null && resDataObj[key] !== ' ') {
                this.showmoreAddinfoTab = true;
              }
              let newObj = { key: key, value: resDataObj[key] }
              this.moreAddtnlInfo.push(newObj);
            }
          }
        } else {
          this.errorMsg = response.msg;
        }
      },
      error => {
        this.errorMsg = error.statusText
      })
  }
  updateProfile() {
    console.log("PROFILE", this.workingDetailData)
  }
  onEditCert() {
    if (this.isenable == false) {
      this.isenable = true;
    }
    else {
      this.isenable = false;
    }

  }
  onFocusOut(item, event) {
    console.log("FOCUS", item);

    console.log("FOCUS", event.target.value);
    for (let i = 0; i < this.workingDetailData.length; i++) {
      if (this.workingDetailData[i].inspworktypeid == item) {
        this.workingDetailData[i].inspworktypevalue = event.target.value;
      }

    }
    console.log(this.workingDetailData, "WORK")
  }
  updateWorkDetails() {

    let data = {
      'inspectorid': this.inspectorid,
    }
    this.profileService.deleteWorkDetail(data).subscribe(
      response => {
        let data = {
          'inspectorid': this.inspectorid,
          'inspworktypevalue': this.workingDetailData
        }
        this.profileService.updateWorkDetail(data).subscribe(
          response => {
            this.isenable = false;
            if (response.success === true) {

            } else {
              this.errorMsg = response.msg;
            }
          },
          error => {
            this.errorMsg = error.statusText
          })

      },
      error => {
        this.errorMsg = error.statusText
      })

  }
  deleteWorkdetail() {

  }
  //   onFocusOut(item){
  //     var textbox = document.getElementById("yourtextbox");
  //     textbox.focus();
  //     textbox.scrollIntoView();
  //     console.log("FOCUS", item)
  // }
}
