import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { MainpanelComponent } from './mainpanel/mainpanel.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { TaskComponent } from './task/task.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ArchiveComponent } from './archive/archive.component';
import { ClaimsComponent } from './claims/claims.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuardService as AuthGuard } from './services/auth/auth-guard.service';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'home', component: MainpanelComponent, canActivate:  [AuthGuard], 
  children:[
          // { path: '',redirectTo: 'home',pathMatch: 'full'},
         // { path: '', component: DashboardComponent},
          { path: '', component: HomeComponent},
          { path: 'dashboard', component: DashboardComponent},
          { path: 'help', component: HelpComponent },
          { path: 'profile', component: ProfileComponent },
          { path: 'task/:claimId', component: TaskComponent },
          { path: 'task', component: TaskComponent },
          { path: 'dashboard', component: DashboardComponent },
          { path: 'archive', component: ArchiveComponent },
          { path: 'claims', component: ClaimsComponent },
          //{ path: '**',  redirectTo: '' }
          { path: '**', redirectTo: '' },
        ]
        
 },
 //{ path: '**', component: PageNotFoundComponent },
 { path: '**', redirectTo: '' }
 
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ enableTracing: false, useHash: true })],
  exports: [RouterModule],
  declarations: []
})

export class AppRoutingModule { }
export const componentsArr=[LoginComponent,NavbarComponent,FooterComponent,DashboardComponent,HelpComponent,HomeComponent,ProfileComponent,TaskComponent,MainpanelComponent,ArchiveComponent,PageNotFoundComponent];
