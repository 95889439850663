import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ArchiveService } from '../services/archive.service';
import { TaskService } from '../services/task.service';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions } from 'mydatepicker';
import { saveAs } from 'file-saver';
import { HelperService } from '../services/helper.service';
@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.css']
})
export class ArchiveComponent implements OnInit {
  @ViewChild('closeBtn' , {static: false}) closeBtn: ElementRef;
  activeTab: boolean = false;
  showTabs: boolean = false;
  loaderSmall: boolean = true;
  activeOrderByLink:boolean = false;
  orderByList: any = [{id:3,label:'Newest',name:'DESC'},{id:2,label:'Oldest',name:'ASC'}];
  claimOverview:any = [];
  claimHistory:any = [];
  claimListData:any = [];
  inspectionList:any = [];
  rejectionStage: any = [];
  commentList: any = [];
  //------show Customer info and Disable Button(submit and schedule)
  customerInfosubAndSchBtn: boolean = false;
  showCustomerDetailArr: any = [6,11,12,13,14,15,16,17];
  dirs: any = [];
  dirArray : any = [];
  actionList: any = [];
  headerDetail: any = [];
  preview: string;
  tabItem:number = 0;
  claimId: number ;
  p: number = 1;
  errorMsg='';
  dataCount:any;
  textfilter:any;
  itemsPerPage:number = 10;
  offset:number = 0;
  rejectClaimid: number = 0;
  config: any;
  fileObj: any;
  inspectorid:any = this.helperService.getInspectorid();
  apiDataObj:any = { inspector_id: this.inspectorid, orderBy: 'DESC', offset: this.offset, rowss: this.itemsPerPage};
  sortType:any;
  loader: boolean = true;
  inspectionForm: FormGroup;
  submissionForm: FormGroup;
  commentForm :  FormGroup;
  constructor(
    private archiveService: ArchiveService,
    private taskService: TaskService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private helperService: HelperService
    ) { 
      //-----Initiate Inspection form 
      this.inspectionForm = this.fb.group({
        filename: ['', Validators.required],
        file: [''],
        insstage: [''],
        remarks: ['',Validators.required]
      })
      //--------Rejection Submit
      this.submissionForm = this.fb.group({
        claimid: ['',Validators.required],
        inspectiondate: ['', Validators.required],
        submitstage: [''],
        amount: ['', [Validators.required, Validators.pattern('^[0-9]+(\.[0-9]*){0,1}$')]],
        remarks: ['']
      })
      //-----Commnet Form ----
      this.commentForm = this.fb.group({
        comment: ['', Validators.required]
      })
    }
  ngOnInit() {
    this.claimListApi(this.apiDataObj);
    this.inspectorDetail();
  }

  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'yyyy-mm-dd',
  };
  private closeModal(): void {
    this.closeBtn.nativeElement.click();
  }
  inspectorDetail(){
    this.helperService.inspectorDetail().subscribe( 
      response => {
          if(response.success === true){
              if(response.data.length >0 ) { 
                this.headerDetail = response.data[0].inspectorDetail[0];
              }else {
                this.toastr.success('No More data found! ','No Data',{
                  timeOut: 3000
                })
              }
          }
      },
      error => { 
        this.errorMsg = error.statusText 
      })
  }

  /* OnClick of OverView Button open the Overview Tab */
  openOverview(index:any,claimId:any){
      this.tabItem=1;
      this.showTabs=true;
      //Open First Tab
      this.tabSelect(this.tabItem);
  }
  tabSelect(e){
    this.tabItem = e;
  }

  //-----Create Tab & Accordian--
  overviewAccSelect(index:any,flag:any){
   // reset the tab & accordian
   this.tabItem=0;
   this.showTabs = false;
    for(let i=0;i<this.claimListData.length;i++) {
        this.claimListData[i].open = false;
        if(i == index) {
            if(flag ===true) {
                this.claimListData[i].open = false;
            } else {
                this.claimListData[i].open = true;
            }
        }
    }
  }
  showCustomerDetail(stageId:number){
    if(this.showCustomerDetailArr.includes(stageId)){
      this.customerInfosubAndSchBtn = true;
    } else {
      this.customerInfosubAndSchBtn = false;
    }
  }
  claimListApi(dataObj:any) {
    this.archiveService.getClaimList(dataObj).subscribe( 
            response => {
                this.claimListData=[];
                this.dataCount = 0;
                if(response.success===true){
                     this.loader = false; 
                    if(response.data.length>0) { 
                      this.claimListData=response.data[0].claimDetail;
                      this.dataCount=response.data[0].rowsCount[0].cnt;
                    }else {
                      this.toastr.success('No More data found! ','No Data',{
                        timeOut: 3000
                      })
                    }
                }
            },
            error => { 
              this.errorMsg = error.statusText 
            })
  }
  //---------Current Page changed-----
  pageChanged(event) {
     this.apiDataObj.offset = (event-1)* this.itemsPerPage ;
     this.claimListApi(this.apiDataObj);
  }
  //---------Update Rows-------
  onChange(rowsValue:any) {
    this.itemsPerPage = rowsValue;
    this.apiDataObj.rowss = this.itemsPerPage;
    this.claimListApi(this.apiDataObj);
  }
  //----------Update Order by option
  getSelectedDropdown(itemId:any,sortType:any){
      for(let i=0;i<this.orderByList.length;i++) {
              this.orderByList[i].active = false;
            if(i === itemId) {
              this.orderByList[i].active = true;
            }
        }
      this.apiDataObj.orderBy = sortType ;
      this.claimListApi(this.apiDataObj);
  } 
  //==================== Tabs Data ===============
  overviewData(claimid:number){
    this.loaderSmall = true;
    this.claimOverview = [];
    let dataObj = { claimid : claimid };
    this.archiveService.getOverview(dataObj).subscribe( 
      response => {
          if(response.success===true){
              this.loaderSmall = false;
              if(response.data.length >0) { 
                this.claimOverview = response.data[0].claimOverview;
              }else {
                this.toastr.success('No More data found! ','No Data',{
                  timeOut: 3000
                })
              }
          }
      },
      error => { 
        this.loaderSmall = false;
        this.errorMsg = error.statusText 
      })
  }
mapView(claimid: any){
    this.dirArray = [];
    claimid = claimid;
    let mapObj = { claimid : claimid ,inspector_id: this.inspectorid };
   // console.log(mapObj);
      //-----map Shows----
 this.dirs = [{
  origin: { lat: 6.8403134, lng: 80.0021128 },
  destination: { lat: 6.71532762, lng: 80.06215197 },
  renderOptions: { polylineOptions: { strokeColor: '#920000' ,  strokeOpacity: 1,strokeWeight: 10, fillColor:'#706bd6'} },
}, 
// {
//   origin: { lat: 6.4319639, lng: 79.9983415 },
//   destination: { lat: 6.73906232, lng: 80.15640132 },
//   renderOptions: { polylineOptions: { strokeColor: '#0f0',radius: 1000 ,strokeOpacity: 1,strokeWeight: 20, fillColor:'#706bd6' } },
// }
];
    // this.archiveService.getlatLong(mapObj).subscribe( 
    //   response => {
    //       if(response.success === true){
    //           if(response.data.length >0) { 
    //             this.dirArray = response.data[0].claimOverview;
    //           }else {
    //             this.toastr.success('No More data found! ','No Data',{
    //               timeOut: 3000
    //             })
    //           }
    //       }
    //   },
    //   error => { 
    //     this.errorMsg = error.statusText 
    //   })
  }

  getClaimHistory(claimid:number){
    this.loaderSmall = true;
    this.claimHistory = [];
    let dataObj={ claimid : claimid };
    this.archiveService.getHistory(dataObj).subscribe( 
      response => {
          if(response.success===true){
              this.loaderSmall = false;
              if(response.data.length >0) { 
                this.claimHistory = response.data[0].claimHistory;
              }else {
                this.toastr.success('No More data found! ','No Data',{
                  timeOut: 3000
                })
              }
          }
      },
      error => {
        this.loaderSmall = false; 
        this.errorMsg = error.statusText 
      })
  }
  //---------Add Static Stage---
  addInspectionStage(){
    this.inspectionForm.get('insstage').setValue(8);
    this.inspectionForm.get('insstage').disable({onlySelf: true});
  }
  //------Inspection List
  getInspection(claimid:number){
    this.inspectionList = [];
    let dataObj= { claimid : claimid };
    this.archiveService.getInspection(dataObj).subscribe( 
      response => {
          if(response.success===true){
              if(response.data.length >0) { 
                this.inspectionList = response.data[0].reportList;
              }else {
                this.toastr.success('No data found! ','No Data',{
                  timeOut: 3000
                })
              }
          }
      },
      error => { 
        this.errorMsg = error.statusText 
      })
  }
   //------Inspection List
  deleteInspection(inspectionId:number){
    let dataObj= { inspectionreportid: inspectionId };
    this.archiveService.delInspection(dataObj).subscribe( 
      response => {
          if(response.success === true) {
              this.toastr.success(response.msg,'deleted',{
                timeOut: 3000
              })
          }else {
            this.toastr.error(response.msg,'Error',{
              timeOut: 3000
            })
          }
      },
      error => { 
        this.errorMsg = error.statusText 
      })
  }
  download(inspectionid: number,claimid: any, fileName: any) {
    claimid = claimid;
    let filename = fileName;
    let downloadObj = { inspector_id: this.inspectorid, claimid: claimid, inspectionreportid: inspectionid };
    this.archiveService.downloadFile(downloadObj).subscribe( 
      response => {
         saveAs(response, filename)
      },
      error => { 
        this.errorMsg = error.statusText 
      })
  }
  //-----Get Form Control-----
  get insForm() { 
    return this.inspectionForm.controls; 
  }
  //-------Get Commnet Form Control-----
  get cmtForm() { 
    return this.commentForm.controls; 
  }
  // Image Preview
  uploadFile(event) {
      const file = (event.target as HTMLInputElement).files[0];
      this.inspectionForm.patchValue({
        file: file
      });
      this.inspectionForm.get('file').updateValueAndValidity()
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
          this.preview = reader.result as string;
      }
      reader.readAsDataURL(file)
  }

  inspecSubmit(claimid:any){
   const { filename,file, remarks, insstage} = this.inspectionForm.getRawValue();
   claimid = claimid;
   let insObj={ filename : filename, file :file, remarks : remarks, inspector_id: this.inspectorid, claimid: claimid, actionid : insstage};
   this.archiveService.addInspection(insObj).subscribe(
    response => {
        if(response.success === true){
            this.toastr.success(response.msg,response.msg,{
              timeOut: 3000
            })
              // Reset Form
              this.inspectionForm.reset();
              //--- Keep Dafault Values after reset
              this.inspectionForm.patchValue({
                file: null,
              });
              this.getInspection(claimid);
              this.inspectionForm.get('insstage').setValue(8);
              this.inspectionForm.get('insstage').disable({onlySelf: true});
        } else {
          this.toastr.error(response.msg,response.msg,{
            timeOut: 3000
          })
        }
    },
    error => { 
      this.errorMsg = error.statusText 
    })
  }
  //-------------Submission Modal-------
  submissionModal(claimid:any){
    this.submissionForm.get('claimid').setValue(claimid);
    this.submissionForm.get('claimid').disable({onlySelf: true});
    this.submissionForm.get('submitstage').setValue(6);
    //this.submissionForm.get('submitstage').disable({onlySelf: true});
  }

  // convenience getter for easy access to form fields
  get submissnForm() { 
    return this.submissionForm.controls; 
  }
  
//--------get Action List---------//
getactionList() {
  this.actionList = [];
  this.archiveService.getactionList().subscribe(response=>{
      if(response.success===true){
          this.actionList = response.data[0].actionList;
      } else {
        this.errorMsg = response.msg;
      }
  },
  error=>{
     this.errorMsg = error.statusText
  })
}
onSubmissionSubmit(){
     //console.log(this.submissionForm.getRawValue());
     let { claimid, inspectiondate, remarks, submitstage, amount} = this.submissionForm.getRawValue();
        claimid = claimid;
        submitstage = parseInt(submitstage);
        amount = parseInt(amount);
     let inspectiondateformatted = `${this.submissionForm.controls['inspectiondate'].value.formatted} 00:00:00`;
     let submissionObj = { claimid: claimid, inspectiondate: inspectiondateformatted, remarks: remarks, inspectorid:  this.inspectorid, actionid: submitstage, amount: amount};
     this.archiveService.onClaimSubmission(submissionObj).subscribe(
          response => {
              if(response.success===true){
                  this.toastr.success(response.msg,response.msg,{
                    timeOut: 3000
                  })
                  // Reset Form
                  this.submissionForm.reset();
                  this.closeModal();
              }else{
                this.toastr.error(response.msg,response.msg,{
                  timeOut: 3000
                })
              }
          },
          error => { 
            this.errorMsg = error.statusText 
          })
  }
  commentSubmit(claimid: any){
    let { comment } = this.commentForm.value;
         claimid = claimid; 
    let commentObj = { inspectorid : this.inspectorid , claimid: claimid, remarks: comment};
    this.taskService.addComment(commentObj).subscribe(
      response => {
          if(response.success === true) {
              this.toastr.success(response.msg,'Success',{
                timeOut: 3000
              })
              // Reset Form
              this.commentForm.reset();
              this.getComment(this.claimId);
          } else {
            this.toastr.error(response.msg,'Error',{
              timeOut: 3000
            })
          }
      },
      error => { 
        this.errorMsg = error.statusText 
      })
  }
  //-------Get Comment----
  getComment(claimid: any){
    claimid = claimid;
    this.claimId = claimid;
    let getcommentObj = { inspectorid : this.inspectorid , claimid: claimid };
    this.taskService.getComment(getcommentObj).subscribe(
      response => {
          if(response.success === true) {
             this.commentList = response.data[0].commentList;
          } else {
            this.toastr.error(response.msg,'Error',{
              timeOut: 3000
            })
          }
      },
      error => { 
        this.errorMsg = error.statusText 
      })
  }
}
