import { AbstractControl,FormGroup } from '@angular/forms';
// export function datesValidator(control: AbstractControl): { [key: string]: boolean } | null {
//     ///TODO: Implement some better validation logic
//     const startdate = control.get('startdate');
//     const enddate = control.get('enddate');
//     console.log(startdate.value.epoc>enddate.value.epoc)
//     if (startdate.pristine || enddate.pristine) {
//       return null;
//     }
//     return startdate && enddate && enddate.value.epoc <= startdate.value.epoc ? { dateInvalid: true } : null;
//   }

  export function datesValidator(controlName: string, matchingControlName: string) {
    return (g: FormGroup) => {
        const startdate = g.controls[controlName];
        const enddate = g.controls[matchingControlName];
        if (enddate.errors && !enddate.errors.dateValidate) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        // set error on matchingControl if validation fails
        //console.log(startdate.value.epoc,enddate.value.epoc);
        if (startdate.value.epoc !=null || enddate.value.epoc!=null){
            if (startdate.value.epoc > enddate.value.epoc) {
                enddate.setErrors({ dateValidate: true });
            } else {
                enddate.setErrors(null);
            }
        }
        
    }
}