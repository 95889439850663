import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class HelperService {
  baseUrl: any = `${environment.baseUrl}`;
  constructor(private http: HttpClient) { }

  //-----Inspector Detail-----
  inspectorDetail(){
    let inspectorObj = { inspector_id: this.getInspectorid() };
    return this.http.post<any>(`${this.baseUrl}inspector/inspectorDetail`, inspectorObj).pipe(catchError(this.errorHandler))
  }
  getInspectorid() {
    let token = localStorage.getItem('token');
    let tokenObj = jwt_decode(token);
    return parseInt(tokenObj.inspectorid);
  }
  getToken() {
    return localStorage.getItem('token');
  }
  errorHandler(error: HttpErrorResponse) {
    return throwError(error)
  }
}
