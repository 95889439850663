import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
   jwtHelper = new JwtHelperService();
  constructor(private router: Router) { }

  public getToken(): string {
    if(localStorage.getItem('token') === null) {
      this.router.navigate(['/login']);
    } else {
      return localStorage.getItem('token');
    }
  }
  public isAuthenticated(): boolean {
    const token = this.getToken();
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

}

