import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringFilter'
})
export class StringFilterPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if(!args){
            return value
        }
        return value.filter( (item: any) => {
            let returnStr;  
            let searchtext = args.trim().toLowerCase();
            let claimid = item.claimid.toString().includes(searchtext); 
            let inpector_stage = item.inpector_stage.toLowerCase().includes(searchtext);
            returnStr = (claimid+inpector_stage);
            if(item.customername!=null){
                let customername = item.customername.toLowerCase().includes(searchtext);
                returnStr= (claimid+inpector_stage+customername)
            }            
            return returnStr;      
        })
    }
}
