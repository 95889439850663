import { Component, OnInit, SecurityContext, ViewChild,ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions } from 'mydatepicker';
import { HomeService } from '../services/home.service';
import { ProfileService } from '../services/profile.service';
import { HelperService } from '../services/helper.service';
import { datesValidator} from '../shared/datesValidator';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  errorMsg='';
  @ViewChild('closeBtnCancel' , {static: false}) closeBtnCancel: ElementRef;
  inspectorData:any=[];
  holidaysListData:any=[];
  myHolidaysList: any=[];
  claimSummary: any=[];
  inspectorid: any;
  delVacId: any;
  displayModal='none';
  preview: any ='assets/img/profile.png';
  holidayForm : FormGroup;
  loader: boolean = true;
  constructor(
    private fb: FormBuilder,
    private homeService: HomeService,
    private toastr: ToastrService,
    private profileService: ProfileService,
    private _sanitizer: DomSanitizer,
    private helperService: HelperService) { 

    }

  ngOnInit() {
      this.inspectorid = this.helperService.getInspectorid();
      let inputObj ={ inspector_id: this.inspectorid };
      this.inspectorDetail(inputObj);
      this.claimSummry(inputObj);
      this.myHolidays(inputObj);
      this.holidaysList();
      this.getProfileImage();
      //----bind data & validation
      this.holidayForm = this.fb.group({
        optRadio: ['1', Validators.required],
        startdate: ['',Validators.required],
        enddate: ['',Validators.required],
        remarks: ['', [Validators.maxLength(200)]],
      },{validator: datesValidator('startdate','enddate') }); 
  }
  
public mytime: Date = new Date();
currentYear: any = this.mytime.getUTCFullYear();
currentDate: any = this.mytime.getUTCDate();
currentMonth: any = this.mytime.getUTCMonth() + 1; //months from 1-12
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    disableUntil: {year: this.currentYear, month: this.currentMonth, day: this.currentDate-1},
    dateFormat: 'yyyy-mm-dd',
  };
  get holidForm() { 
    return this.holidayForm.controls; 
  }
  //----------date Picker
  openModalDialog(){
      this.displayModal='block'; //Set block css
  }
  closeModalDialog(){
      this.displayModal='none'; //set none css after close dialog
  }

 inspectorDetail(Obj){
    this.homeService.inspectorDetail(Obj).subscribe(
      response => {
        this.inspectorData=response.data[0].inspectorDetail[0];
      },
      error => { 
        this.errorMsg = error.statusText 
      })
 }
 myHolidays(Obj){
    this.homeService.myHolidays(Obj).subscribe(
      response => {
          this.myHolidaysList=response.data[0].getMyVacation;
      },
      error => { 
        this.errorMsg = error.statusText 
      })
 }
 claimSummry(Obj){
  this.homeService.claimSummary(Obj).subscribe(
    response => {
      //if(response.data.length>0){
        if(response.success == true) {
          this.loader = false;
          //this.claimSummary = [];
          this.claimSummary = response.data[0].summaryCount;
        }else {
          this.loader = false;
        }
    },
    error => { 
      this.errorMsg = error.statusText 
    })
}
 holidaysList(){
      this.homeService.holidaysList().subscribe(
        response => {
          if(response.data.length >0) {
            this.holidaysListData=response.data[0].holidayList;
          }
        },
        error => { 
          this.errorMsg = error.statusText 
        }
      )
 }
 //-----------get form data-----
onSubmit(){ 
      let { optRadio, remarks } = this.holidayForm.value;
      let startdate = `${this.holidayForm.controls['startdate'].value.formatted} 00:00:00`;
      let enddate= `${this.holidayForm.controls['enddate'].value.formatted} 00:00:00`;
      let dataObj= { inspector_id: this.inspectorid, startdate: startdate, enddate:enddate, vacationtype: optRadio, remarks: remarks};
      this.homeService.addHolidays(dataObj).subscribe(
              response => {
                  if(response.success===true){
                      let inputObj ={ inspector_id: this.inspectorid};
                      this.myHolidays(inputObj);
                      //----Reset form after submit
                      this.holidayForm.reset();
                      //---Afer Reset keep Dafault Values
                      this.holidayForm.patchValue({
                        optRadio: '1',
                      });
                      this.toastr.success(response.msg,response.msg,{
                        timeOut: 3000
                      })
                  }
              },
              error => { 
                this.errorMsg = error.statusText 
              })
    }
    //-------del Vacation Id 
    delVacationId(vacId:any){
      this.delVacId = vacId;
    }
    clickConfirmation(args:any){
      if(args ==1){
          this.delVacation();
      } else {
        this.closeModal();
      }
    }
    public closeModal(): void {
      this.closeBtnCancel.nativeElement.click();
    }
    //----------Delete Vacation-----
    delVacation() {
      let dataObj= { vacation_id: this.delVacId};
        this.homeService.delVacation(dataObj).subscribe(
          response => {
              if(response.success===true){
                     this.closeModal();
                let inputObj = { inspector_id: this.inspectorid };
                this.myHolidays(inputObj);
                  this.toastr.success(response.msg,response.msg,{
                    timeOut: 3000
                  }) 
              } else {
                this.toastr.error(response.msg,response.msg,{
                  timeOut: 3000
                })
              }
          },
          error => { 
            this.errorMsg = error.statusText 
          })
    }
    getProfileImage(){
      let getimageObj ={ inspectorid : this.inspectorid}
      this.profileService.getProfileImage(getimageObj).subscribe(
        response => {
          if(response.success === true){
            if(response.data.length >0) {
              this.preview = response.data[0].profileImage[0].profile_image;
            }
        } else {
              this.errorMsg=response.msg;
         }
        },
        error => { 
          this.errorMsg = error.statusText 
        })
    }
    public get imageProperty() : SafeHtml {
      if(this.preview === null){
        this.preview = '../../assets/img/profile.png'
        return this.preview;
      } else {
        return this._sanitizer.sanitize(SecurityContext.HTML, this.preview);
      }
   }
}
