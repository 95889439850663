import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  baseUrl: any = `${environment.baseUrl}profile`;
  constructor(private http: HttpClient) { }

  workingDetail(dataObj:any){
    return this.http.post<any>(`${this.baseUrl}/workingDetail`, dataObj).pipe(catchError(this.errorHandler))
  }

  uploadInsImage(dataObj:any){
    return this.http.post<any>(`${this.baseUrl}/addProfilePic`, dataObj).pipe(catchError(this.errorHandler))
  }
  getProfileImage(dataObj:any){
    return this.http.post<any>(`${this.baseUrl}/getprofileImage`, dataObj).pipe(catchError(this.errorHandler))
  }
  //--------On Edit Profile
  editProfileData(profObj:any){
    return this.http.post<any>(`${this.baseUrl}/editInspector`, profObj).pipe(catchError(this.errorHandler))
  }
  //-----Company List
  companyList(){
    return this.http.get<any>(`${this.baseUrl}/companyList`).pipe(catchError(this.errorHandler))
  }
  //----------country List------
  countryList(){
    return this.http.get<any>(`${this.baseUrl}/countryList`).pipe(catchError(this.errorHandler))
  }
  //----------State List------
  stateList(stateObj:any){
    return this.http.post<any>(`${this.baseUrl}/stateList`, stateObj).pipe(catchError(this.errorHandler))
  }
  //----------City List------
  cityList(cityObj:any){
    return this.http.post<any>(`${this.baseUrl}/cityList`, cityObj).pipe(catchError(this.errorHandler))
  }
  //----------Zip List------
  zipList(zipObj:any){
    return this.http.post<any>(`${this.baseUrl}/zipList`, zipObj).pipe(catchError(this.errorHandler))
  }
  //-----Update Profile -----
  updateProfile(updateObj:any) {
    return this.http.post<any>(`${this.baseUrl}/updateProfile`, updateObj).pipe(catchError(this.errorHandler))
  }
  updateAddInfo(updateObj:any) {
    return this.http.post<any>(`${this.baseUrl}/updateAddInfo`, updateObj).pipe(catchError(this.errorHandler))
  }
  getAddInfo(updateObj:any) {
    return this.http.post<any>(`${this.baseUrl}/getAddInfo`, updateObj).pipe(catchError(this.errorHandler))
  }
  additionalDetail(dataObj:any){
    return this.http.post<any>(`${this.baseUrl}/additionalDetail`, dataObj).pipe(catchError(this.errorHandler))
  }
  updateWorkDetail(dataObj:any){
    return this.http.post<any>(`${this.baseUrl}/updateWorkdetail`, dataObj).pipe(catchError(this.errorHandler))
  }
 deleteWorkDetail(dataObj:any){
    return this.http.post<any>(`${this.baseUrl}/deleteWorkDetail`, dataObj).pipe(catchError(this.errorHandler))
  }
  errorHandler(error: HttpErrorResponse) {
      return throwError(error)
    }
}
