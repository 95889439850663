import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { HelpService } from '../services/help.service';
import { HelperService } from '../services/helper.service';
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  contactForm: FormGroup;
  preview: string;
  errorMsg='';
  inspectorid: any;
  browseFilename: any;
  reasonListData: any =[];
  faqListData: any =[];
  loader: boolean = true;
  constructor( 
    private fb: FormBuilder,
    private helpService: HelpService,
    private toastr: ToastrService,
    private helperService: HelperService
    ) {
    // Reactive Form
    this.contactForm = this.fb.group({
      reason: ['', Validators.required],
      file: [''],
      remark: ['', Validators.required]
    })
  }
  ngOnInit() {
    this.inspectorid = this.helperService.getInspectorid();
    this.reasonList();
    this.faqList();
    //this.overviewAccSelect(0);
  }
  get contactF() { 
    return this.contactForm.controls; 
  }
   // Image Preview
   uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.browseFilename= file.name;
    this.contactForm.patchValue({
      file: file
    });
    this.contactForm.get('file').updateValueAndValidity()
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.preview = reader.result as string;
    }
    reader.readAsDataURL(file)
  }
  reasonList(){
    this.helpService.reasonList().subscribe(response=>{
        if(response.success===true){
            this.reasonListData = response.data;
        } else {
          this.errorMsg = response.msg 
        }
    },
    error=>{
      this.errorMsg = error.statusText
    })
  }
  faqList(){
    this.helpService.faqList().subscribe(response=>{
        if(response.success===true){
            this.loader = false;
            this.faqListData = response.data;
        } else {
           this.errorMsg = response.msg 
        }
    },
    error=>{
      this.errorMsg = error.statusText 
    })
  }
  submitForm(){
    //console.log(this.contactForm.value,this.contactForm.value.file.name);
   const {reason,file, remark} = this.contactForm.value;
   let contactObj={ reason:reason, file :file, remark:remark,inspector_id: this.inspectorid};
   this.helpService.contactUs(contactObj).subscribe(
    response => {
        if(response.success===true){
            this.toastr.success(response.msg,response.msg,{
              timeOut: 3000
            })
            // Reset Form
            this.contactForm.reset();
            //---Afer Reset keep Dafault Values
            this.contactForm.patchValue({
              file: null,
            });
        }else{
          this.toastr.error(response.msg,response.msg,{
            timeOut: 3000
          })
        }
    },
    error => { 
      this.errorMsg = error.statusText 
    })
  }
  overviewAccSelect(index:any, flag: any){
    for(let i=0;i<this.faqListData.length;i++) {
        this.faqListData[i].open = false;
        this.faqListData[i].in = false;
        if(i == index) {
            if(flag ===true) {
              this.faqListData[i].open = false;
              this.faqListData[i].in = false;
            } else {
              this.faqListData[i].open = true;
              this.faqListData[i].in = true;
            }
        }
    }
  }
}
